<template>
  <div>
    <el-card>
      <el-form ref="form" :inline="true">
        <el-form-item label="分类">
          <el-select
            v-model="queryForm.type"
            placeholder="请选择分类"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in auxiliaryTypeData"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="标题">
          <el-input
            placeholder="请输入标题"
            v-model="queryForm.title"
          ></el-input>
        </el-form-item>

        <el-form-item label="时间">
          <el-date-picker
            type="datetimerange"
            v-model="times"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="toQuery" icon="el-icon-search"
            >查询</el-button
          >
        </el-form-item>
       
        <el-form-item class="generate">
          <el-button type="primary" @click="toAdd"
            >添加助词</el-button
          >
        </el-form-item>
         <el-form-item class="generate">
          <el-button type="primary" @click="setAuxiliaryVideo"
            >设置介绍视频</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="auxiliaryList"
        stripe
        class="table"
        ref="userTable.multipleSelection"
        header-cell-class-name="table-header"
        border
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="id"
          label="ID"
          width="55"
          align="center"
        ></el-table-column>
       
        <el-table-column label="分类" prop="type" align="center"></el-table-column>
        <el-table-column label="标题" prop="title" align="center"></el-table-column>
        <el-table-column label="时间" align="center">
          <template slot-scope="scope">
            {{
                $moment(scope.row.create_time * 1000).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
          </template>
        </el-table-column>
       
        <el-table-column label="操作" align="center" >
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="editGoods(scope.row)"
              size="small"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="removeTask(scope.row.id)"
              size="small"
            ></el-button>
            <el-button
              type="primary"
              icon="el-icon-tickets"
              circle
              @click="openOptions(scope.row.id)"
              size="small"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>

       <!-- 分页区域 -->
     <div class="pagination">
       <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.page"
        :page-sizes="[3, 5, 8, 10]"
        :page-size="queryForm.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        algin:="center"
      ></el-pagination>
     </div>

    <!-- 添加助词 -->
    <el-dialog :title="addtitle" :visible.sync="addDialog" :center=true width="40%" @close="closed" :close-on-click-modal=false>
      <el-form :model="addForm"  ref="addForm">
        <el-form-item el-form-item label="分类" label-width="100px">
            <el-select
                v-model="addForm.type"
                placeholder="请选择分类"
                clearable
              >
                <el-option
                  :label="item.value"
                  :value="item.key"
                  v-for="item in auxiliaryTypeData"
                  :key="item.key"
                ></el-option>
            </el-select>
        </el-form-item>
        <el-form-item el-form-item label="标题" label-width="100px">
            <el-input
              type="text"
              v-model="addForm.title"
              placeholder="请输入标题"
            />
        </el-form-item>

        <el-form-item el-form-item label="视频" label-width="100px">
          <div v-if="addForm.video_url" class="video_box">
            <video
              :src="addForm.video_url"
              style="cursor: pointer;outline: none;"
              controls="controls" 
              ref="videoPlay"
            ></video>
          </div>
          <el-button type="primary" @click="selectvideo"
            >{{isAdd=='add'?'上传视频':'修改视频'}}</el-button
          ><span class="file-name">{{ fileName }}</span>
          <el-progress :percentage="progress" v-if="progress"></el-progress>
          <input
            type="file"
            hidden
            ref="video"
            @change="selectVideoFiles"
            accept="video/*"
          />
        </el-form-item>

        <el-form-item el-form-item label="内容" label-width="100px">
          <quill-editor
              v-model="addForm.content"
              ref="myQuillEditor"
            >
          </quill-editor>
        </el-form-item>

        <el-form-item label-width=40% style="margin-top: 20px;">
           <el-button type="primary" v-if="isAdd=='add'" @click="addNow" >立即添加</el-button>
           <el-button type="primary" v-else @click="editReadNow">立即修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 设置日语APP助词介绍视频-->
    <el-dialog title="设置介绍视频" :visible.sync="addAuxiliaryVideoDialog" :center=true width="40%" @close="closeAuxiliaryVideo" :close-on-click-modal=false>
      <el-form :model="addAuxiliaryData"  ref="addAuxiliaryVideoForm">
        <el-form-item el-form-item label="视频" label-width="100px">
          <div v-if="addAuxiliaryData.video_url" class="video_box">
            <video
              :src="addAuxiliaryData.video_url"
              style="cursor: pointer;outline: none;"
              controls="controls" 
              ref="setVideoPlay"
            ></video>
          </div>
          <el-button type="primary" @click="setVideo"
            >{{addAuxiliaryData.video_url?'修改视频':'上传视频'}}</el-button
          ><span class="file-name">{{ setFileName }}</span>
          <el-progress :percentage="setProgress" v-if="setProgress"></el-progress>
          <input
            type="file"
            hidden
            ref="setVideo"
            @change="setVideoFiles"
            accept="video/*"
          />
        </el-form-item>

        <el-form-item label-width=40% style="margin-top: 20px;">
           <el-button type="primary" @click="setNow" >{{addAuxiliaryData.video_url?'立即修改':'立即上传'}}</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 日语APP助词练习题列表 -->
    <el-dialog title="练习题列表" :visible.sync="optionsDialog" :center=true width="80%" :close-on-click-modal=false>
      <div class="title_box">
          <div></div>
          <div class="hearingLibDialog_title">
               <el-button type="primary" @click="toAddOptions">添加练习题</el-button>
          </div>
      </div>

      <el-table
        :data="auxiliarySubjectList"
        stripe
        class="table"
        ref="userTable.multipleSelection"
        header-cell-class-name="table-header"
        border
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="id"
          label="ID"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column label="题目名" prop="subject" align="center"></el-table-column>
        <el-table-column label="答案" prop="correct" align="center"></el-table-column>
        <el-table-column label="创建时间" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.create_time !== null">
              {{
                $moment(scope.row.create_time * 1000).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
            </div>
            <div v-else>空</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" width="150">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="editOptions(scope.row)"
              size="small"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="removeOptions(scope.row.id)"
              size="small"
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>


    <!-- 添加练习题-->
    <el-dialog :title="addOptionsTitle" :visible.sync="addOptionsDialog" :center=true width="40%" @close="closeaddOptions" :close-on-click-modal=false>
      <el-form :model="addOptionsData"  ref="addAuxiliaryVideoForm">
        <el-form-item el-form-item label="题目名：" label-width="100px">
           <el-input v-model="addOptionsData.subject" placeholder="请输入题目名"></el-input>
        </el-form-item>

        <el-form-item el-form-item label="添加选项：" label-width="100px">
            <div class="addtopic">
              <div></div>
              <el-button type="primary" @click="addTopicOptions">添加选项</el-button>
            </div>
            <div class="add_box" v-for="(item,index) in addOptionsData.options" :key="index">
                <div style="display:flex">
                    <el-select
                      v-model="item.key"
                      placeholder="请选择题目选项"
                      clearable
                    >
                      <el-option
                        :label="item.value"
                        :value="item.key"
                        v-for="item in optionsArr"
                        :key="item.key"
                      ></el-option>
                    </el-select>
                    <el-input v-model="item.value" placeholder="请输入题目选项名" style="margin:0 30px;width=100px"></el-input>
                </div>
            </div>
        </el-form-item>

        <el-form-item el-form-item label="答案" label-width="100px">
          <el-select
            v-model="addOptionsData.correct"
            placeholder="请选择题目答案"
            clearable
          >
            <el-option
              :label="item.value"
              :value="item.key"
              v-for="item in optionsArr"
              :key="item.key"
            ></el-option>
          </el-select>
        </el-form-item>

       
        <el-form-item label-width=40% style="margin-top: 20px;">
           <el-button type="primary" v-if="isAddOptions=='add'" @click="addOptinsNow" >立即添加</el-button>
           <el-button type="primary" v-else @click="editOptionsNow">立即修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    </el-card>
  </div>
</template>

<script>
//引入富文本组件
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
var COS = require("cos-js-sdk-v5");

import {
  AuxiliaryList,
  AuxiliaryDel,
  AuxiliaryAdd,
  AuxiliaryEdit,
  getAuxiliaryCosToken,
  AuxiliaryType,
  AuxiliaryVideo,
  setAuxiliaryVideo,
  AuxiliarySubjectList,
  AuxiliarySubjectAdd,
  AuxiliarySubjectEdit,
  AuxiliarySubjectDel,
} from "@/api/Japanese_app/auxiliary.js";
export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      queryForm: {
        type: "",
        title: "",
        page: 1,
        limit: 10,
        start_time: null,
        end_time: null,
      },
      auxiliaryList: [],
      auxiliaryTypeData: [],
      total: 0,
      addDialog: false,
      times: null,
      addForm: {
        type: "",
        title: "",
        content: "",
        video: "",
      },
      addtitle: "",
      isAdd: "add",
      fileName: "",
      progress: 0,
      addAuxiliaryVideoDialog: false,
      addAuxiliaryData: {
        video: "",
      },
      setProgress: 0,
      setFileName: "",
      optionsDialog: false,
      auxiliarySubjectId: null,
      auxiliarySubjectList: [],
      addOptionsTitle: "",
      isAddOptions: "add",
      addOptionsDialog: false,
      addOptionsData: {
        auxiliary_id: "",
        subject: "",
        correct: "",
        options: [],
      },
      optionsArr: [
        { value: "A", key: "A" },
        { value: "B", key: "B" },
        { value: "C", key: "C" },
        { value: "D", key: "D" },
        { value: "E", key: "E" },
        { value: "F", key: "F" },
      ],
    };
  },

  created() {
    this.getAuxiliaryType();
    this.getAuxiliaryList();
  },
  methods: {
    //   日语APP助词类型
    getAuxiliaryType() {
      AuxiliaryType().then((res) => {
        this.auxiliaryTypeData = res.data;
      });
    },
    //   日语APP助词列表
    getAuxiliaryList() {
      AuxiliaryList(this.queryForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.total = res.data.total;
        this.auxiliaryList = res.data.data;
      });
    },

    // 删除助词
    async removeTask(id) {
      const result = await this.$confirm("是否要删除该助词?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result !== "confirm") return this.$message.info("已经取消删除");
      AuxiliaryDel({ id: id }).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("删除助词成功");
        this.getAuxiliaryList();
      });
    },

    // 添加弹窗
    toAdd() {
      this.isAdd = "add";
      this.addtitle = "添加助词";
      this.addDialog = true;
    },
    // 点击选择视频文件按钮
    selectvideo() {
      this.$refs.video.click();
    },

    // 选中视频
    selectVideoFiles(e) {
      let file = this.$refs.video.files[0];
      this.fileName = file.name;
      const isSize = file.size / 1024 / 1024 < 200;
      if (!isSize) {
        this.$message.error("上传视频不能超过 200MB!");
        return;
      }

      let that = this;
      getAuxiliaryCosToken({ suffix: file.name }).then((res) => {
        var cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId,
            });
          },
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {
              that.progress = progressData.percent * 100;
            },
          },
          function (err, data) {
            if (data) {
              that.addForm.video = res.data.key;
            }
            e.target.value = "";
          }
        );
      });
    },

    // 添加助词
    addNow() {
      if (this.addForm.type == "") {
        this.$message.error("请选择分类");
        return;
      } else if (this.addForm.title == "") {
        this.$message.error("请输入标题");
        return;
      } else if (this.addForm.video == "") {
        this.$message.error("请上传视频");
        return;
      } else if (this.addForm.content == "") {
        this.$message.error("请输入内容");
        return;
      }
      AuxiliaryAdd(this.addForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("添加助词成功");
        this.addDialog = false;
        this.getAuxiliaryList();
      });
    },

    // 点击编辑图标
    editGoods(item) {
      this.isAdd = "edit";
      this.addtitle = "编辑助词";
      this.addDialog = true;
      this.addForm = JSON.parse(JSON.stringify(item));
    },

    // 点击立即修改
    editReadNow() {
      if (this.addForm.type == "") {
        this.$message.error("请选择分类");
        return;
      } else if (this.addForm.title == "") {
        this.$message.error("请输入标题");
        return;
      } else if (this.addForm.video == "") {
        this.$message.error("请上传视频");
        return;
      } else if (this.addForm.content == "") {
        this.$message.error("请输入内容");
        return;
      }
      AuxiliaryEdit(this.addForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("修改助词成功");
        this.addDialog = false;
        this.getAuxiliaryList();
      });
    },

    // 弹窗关闭事件
    closed() {
      this.addForm = {
        type: "",
        title: "",
        thumb: "",
        video: "",
      };
      this.fileName = "";
      this.progress = 0;

      if (this.$refs.videoPlay !== undefined) {
        this.$refs.videoPlay.pause();
      }
    },
    // 设置日语APP助词介绍视频
    setAuxiliaryVideo() {
      this.addAuxiliaryVideoDialog = true;
      AuxiliaryVideo().then((res) => {
        if (Array.isArray(res.data)) {
          this.addAuxiliaryData = { video: "" };
        } else {
          this.addAuxiliaryData = res.data;
        }
      });
    },
    // 点击选择设置视频
    setVideo() {
      this.$refs.setVideo.click();
    },
    setVideoFiles(e) {
      let file = this.$refs.setVideo.files[0];
      this.setFileName = file.name;
      const isSize = file.size / 1024 / 1024 < 200;
      if (!isSize) {
        this.$message.error("上传视频不能超过 200MB!");
        return;
      }

      let that = this;
      getAuxiliaryCosToken({ suffix: file.name }).then((res) => {
        var cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId,
            });
          },
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {
              that.setProgress = progressData.percent * 100;
            },
          },
          function (err, data) {
            if (data) {
              that.addAuxiliaryData.video = res.data.key;
            }
            e.target.value = "";
          }
        );
      });
    },

    // 点击立即设置介绍视频
    setNow() {
      setAuxiliaryVideo(this.addAuxiliaryData).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("设置介绍视频成功");
        this.addAuxiliaryVideoDialog = false;
        this.getAuxiliaryList();
      });
    },
    // 设置视频弹框关闭
    closeAuxiliaryVideo() {
      this.addAuxiliaryData = {
        video: "",
      };
      this.setProgress = 0;
      this.setFileName = "";
      if (this.$refs.setVideoPlay !== undefined) {
        this.$refs.setVideoPlay.pause();
      }
    },

    // 打开题目列表
    openOptions(id) {
      this.auxiliarySubjectId = id;
      this.getAuxiliarySubjectList(id);
      this.optionsDialog = true;
    },

    // 获取题目列表
    getAuxiliarySubjectList(id) {
      AuxiliarySubjectList({ auxiliary_id: id }).then((res) => {
        this.auxiliarySubjectList = res.data;
      });
    },
    // 添加题目
    toAddOptions() {
      this.addOptionsTitle = "添加题目";
      this.isAddOptions = "add";
      this.addOptionsDialog = true;
    },

    // 添加题目选项
    addTopicOptions() {
      this.addOptionsData.options.push({ key: "", value: "" });
    },

    // 去除空标签
    trimSpace(array) {
      for (var i = 0; i < array.length; i++) {
        if (array[i].value == "") {
          array.splice(i, 1);
          i = i - 1;
        }
      }
      return array;
    },
    // 点击立即添加题目
    addOptinsNow() {
      this.trimSpace(this.addOptionsData.options);
      this.addOptionsData.auxiliary_id = this.auxiliarySubjectId;
      if (this.addOptionsData.subject == "") {
        this.$message.error("请输入题目名");
        return;
      } else if (this.addOptionsData.options.length == 0) {
        this.$message.error("请添加题目选项");
        return;
      } else if (this.addOptionsData.correct == "") {
        this.$message.error("请选择题目正确答案");
        return;
      }
      AuxiliarySubjectAdd(this.addOptionsData).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("添加题目成功");
        this.addOptionsDialog = false;
        this.getAuxiliarySubjectList(this.auxiliarySubjectId);
      });
    },

    // 点击修改题目
    editOptions(item) {
      this.addOptionsTitle = "修改题目";
      this.isAddOptions = "edit";
      this.addOptionsDialog = true;
      this.addOptionsData = JSON.parse(JSON.stringify(item));
    },

    // 点击删除题目
    async removeOptions(id) {
      const result = await this.$confirm("是否要删除该题目?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result !== "confirm") return this.$message.info("已经取消删除");
      AuxiliarySubjectDel({ subject_id: id }).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("删除题目成功");
        this.getAuxiliarySubjectList(this.auxiliarySubjectId);
      });
    },

    // 点击立即修改题目
    editOptionsNow() {
      this.trimSpace(this.addOptionsData.options);
      this.addOptionsData.subject_id = this.addOptionsData.id;
      if (this.addOptionsData.subject == "") {
        this.$message.error("请输入题目名");
        return;
      } else if (this.addOptionsData.options.length == 0) {
        this.$message.error("请添加题目选项");
        return;
      } else if (this.addOptionsData.correct == "") {
        this.$message.error("请选择题目正确答案");
        return;
      }
      AuxiliarySubjectEdit(this.addOptionsData).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("修改题目成功");
        this.addOptionsDialog = false;
        this.getAuxiliarySubjectList(this.auxiliarySubjectId);
      });
    },

    // 添加题目弹框关闭事件
    closeaddOptions() {
      this.addOptionsData = {
        auxiliary_id: "",
        subject: "",
        correct: "",
        options: [],
      };
    },

    //  查询
    toQuery() {
      this.queryForm.page = 1;
      if (this.times) {
        this.queryForm.start_time = new Date(this.times[0]).getTime() / 1000;
        this.queryForm.end_time = new Date(this.times[1]).getTime() / 1000;
      } else if (this.times == null) {
        this.queryForm.start_time = null;
        this.queryForm.end_time = null;
      }
      this.getAuxiliaryList();
    },
    handleSizeChange(size) {
      this.queryForm.limit = size;
      this.getAuxiliaryList();
    },
    handleCurrentChange(page) {
      this.queryForm.page = page;
      this.getAuxiliaryList();
    },
  },
};
</script>

<style scoped lang="less">
.generate {
  float: right;
}
.el-tag {
  cursor: pointer;
}
::v-deep .ql-editor {
  min-height: 400px;
}
.thumb_box {
  width: 200px;
  height: 120px;
  img {
    width: 100%;
    height: 100%;
  }
}

.video_box {
  width: 100%;
  height: 300px;
  margin-bottom: 10px;
  video {
    width: 100%;
    height: 100%;
  }
}
.title_box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
}
.addtopic {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.add_box {
  margin-bottom: 20px;
}
</style>
